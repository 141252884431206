import { useState } from 'react';
import Badge from 'components/organisms/buy-credits/game-product/badge';
import { ModalRowCentered } from 'components/organisms/terms-and-conditions/terms-modal/styled';
import { Button, OutlinedButton } from 'components/atoms/button';
import { useNavigate } from 'hooks/use-navigate';
import { Routes } from 'common/routes';
import styled from '@emotion/styled';
import { Timer } from 'components/organisms/buy-credits/game-product/timer';
import Chip from 'components/organisms/buy-credits/game-product/chip';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { GameProduct } from 'services/get-game-products/get-game-products';

interface ModalContainerSelectedContentProps {
  isModal?: boolean;
}

export const ModalContainerSelectedContent = styled.div<ModalContainerSelectedContentProps>`
  background-color: ${(props) => (props.isModal ? 'unset' : '#323232')};
  border-radius: var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: var(--spacing-3);
`;
export const ModalSelectedContent = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  position: relative;

  .play-now {
    transform: skew(-20deg);
    background-color: #f4a60d;
    border-radius: 8px;
    filter: drop-shadow(1px 1px 1px black);
  }
  .play-now > span {
    display: block;
    text-decoration: none;
    padding: 2px 16px;
    font-weight: bold;
    color: black;
    transform: skew(20deg);
  }

  .play-later {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    padding: 2px 16px;
    z-index: 1;
    border-radius: 8px;
  }

  .underline-click {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export type SelectModalProps = {
  onSeeWagerDetails?(product: GameProduct): void;
  onClickBadge?(event: any, product: GameProduct): void;
  product: GameProduct;
  instantPlayEligible: any;

  isModal?: boolean;
  onConfirm: (product: GameProduct) => Promise<void>;
};
export const SelectedGameProduct = ({
  product,
  isModal,
  instantPlayEligible,
  onSeeWagerDetails,
  onClickBadge,
  onConfirm,
}: SelectModalProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const seeWagerDetails = () => {
    onSeeWagerDetails(product);
  };

  const confirm = async () => {
    //place order
    setLoading(true);
    setFeedback(null);
    const res = await onConfirm(product);
    setFeedback(res);
    console.log(res);
    setLoading(false);
  };

  return (
    <div className='d-flex flex-column w-100 col col-12 col-md-6 col-lg-6 text-center pt-2'>
      <ModalContainerSelectedContent isModal={isModal}>
        <ModalSelectedContent>
          <div className='mx-auto py-3'>
            {product.expected_wait === 'IMMEDIATE' && (
              <div className='play-now' data-testid='play-now-tag'>
                <span>Play Now</span>
              </div>
            )}
            {product.expected_wait !== 'IMMEDIATE' && instantPlayEligible && (
              <div className='play-later'>
                <span>
                  Play Later <Timer size={'15px'} />
                </span>
              </div>
            )}
          </div>
          <div className='mx-auto py-3'>
            <Chip
              relative
              size={'150px'}
              product={product}
              onClickChip={() => {}}
            />
          </div>
          <div className={'py-3'}>
            <Badge product={product} onClickBadge={onClickBadge} />
          </div>

          <div className='text-left'>
            Deduct ${product.cost} from my Cash Account. All horse race wagers
            are final.&nbsp;
            <span
              data-testid='wager-details-link'
              className='underline-click'
              onClick={seeWagerDetails}
            >
              See wager details.
            </span>
          </div>
        </ModalSelectedContent>

        {feedback && (
          <BspotAlert
            type={feedback === 'Geolocation not found' ? 'error' : 'success'}
            message={feedback}
          />
        )}

        <ModalRowCentered>
          {!feedback && (
            <Button
              text={
                product.expected_wait === 'IMMEDIATE'
                  ? 'Confirm & Play Now'
                  : instantPlayEligible
                  ? 'Confirm & Play Later'
                  : 'Confirm & Place Final Wager'
              }
              isLoading={loading}
              onClick={confirm}
            />
          )}

          {/*{feedback && (*/}
          {/*  <OutlinedButton*/}
          {/*    text='Play Now!'*/}
          {/*    onClick={() => navigate(Routes.Lobby)}*/}
          {/*  />*/}
          {/*)}*/}
        </ModalRowCentered>
      </ModalContainerSelectedContent>
    </div>
  );
};
export default SelectedGameProduct;
