import styled from '@emotion/styled';
import { TextSize } from 'components/atoms/text';
import 'common/theme/main.scss';
import { Link } from 'components/atoms/link';
import { Routes } from 'common/routes';
import { FbSocial } from 'icons/svg/fb-social';
import { XSocial } from 'icons/svg/x-social';
import { IgSocial } from 'icons/svg/ig-social';
import React, { ReactNode, useState } from 'react';
import { Compass } from 'icons/svg/compass';
import { Help } from 'icons/svg/help';
import { Legal } from 'icons/svg/legal';
import { CONFIG } from 'common/utils';
import { useAuth } from 'contexts/authentication';
import { useLocation } from 'react-router-dom';

const FooterBar = styled.footer`
  background-color: var(--footer-background);
  color: var(--text-light-color);
  font-size: 14px;
  font-family: Poppins, serif;
  padding: 80px 0;
  hr {
    height: 1px;
    background-color: var(--bg-darkish-color);
    border: none;
  }
`;

const badges = [
  {
    path: '/assets/images/powered-by-GPN.svg',
    href: 'https://gameplaynetwork.com/powered-by/',
    height: '32px',
    heightLg: '65px',
  },
  {
    path: '/assets/images/powered-by-horse-racing.webp',
    href: 'https://gameplaynetwork.com/how-it-works/',
    height: '28px',
    heightLg: '46px',
  },
  {
    path: '/assets/images/b-safe-responsible-gaming.svg',
    href: Routes.ResponsibleGaming.path,
    height: '32px',
    heightLg: '52px',
  },
  {
    path: '/assets/images/us-licensed-badge.svg',
    height: '36px',
    heightLg: '60px',
  },
];

const ExploreLinks = {
  title: 'Explore ' + CONFIG.BRAND,
  items: [
    {
      label: 'Games',
      link: 'https://www.bspot.com/',
    },
    {
      label: 'Promotions',
      link: 'https://www.bspot.com/en/promos',
    },
    {
      label: 'Tournaments',
      link: 'https://www.bspot.com/en/tournaments',
    },
  ],
};

const HelpLinks = {
  title: 'Help center',
  items: [
    {
      label: 'Help',
      link: 'https://support.bspot.com/hc/en-us',
    },
    {
      label: 'Responsible Gaming',
      link: 'https://www.bspot.com/en/responsible-gaming',
    },
    {
      label: 'Affiliates',
      link: 'https://www.bspot.com/affiliates',
    },
  ],
};

const LegalLinks = {
  title: 'Legal',
  items: [
    {
      label: 'About',
      link: 'https://www.bspot.com/en/about-us',
    },
    {
      label: 'Terms of use',
      link: 'https://www.bspot.com/en/terms-and-conditions',
    },
    {
      label: 'Privacy policy',
      link: 'https://www.bspot.com/en/privacy-policy',
    },
  ],
};

type FooterLinksProps = {
  title: string;
  icon: ReactNode;
  items: {
    label: string;
    link: string;
  }[];
};

export const MobileFooterLinks = ({ title, items, icon }: FooterLinksProps) => {
  const [collapse, setCollapse] = useState(true);
  return (
    <div className={'d-flex flex-column'}>
      <div
        className={'d-flex justify-content-between align-items-center'}
        onClick={() => setCollapse(!collapse)}
      >
        <div className={'d-flex gap-1'}>
          {icon}
          <div style={{ color: 'var(--primary-color)' }} className={'fw-bold'}>
            {title}
          </div>
        </div>
        <span style={{ color: 'var(--primary-color)', fontSize: '24px' }}>
          {' '}
          +{' '}
        </span>
      </div>
      {!collapse && (
        <div className={'d-flex flex-column gap-2 pb-2'}>
          {items.map((it, index) => (
            <div className={'d-flex gap-1'} key={index}>
              <span style={{ width: '20px' }}></span>

              {it.link ? (
                <Link
                  color={'#ffffff'}
                  style={{ textDecoration: 'underline', fontSize: '14px' }}
                  href={it.link}
                  target={'_blank'}
                >
                  {it.label}
                </Link>
              ) : (
                <>
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                     // window.Bspot.liveChat.open();
                    }}
                  >
                    {' '}
                    {it.label}
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const DesktopFooterLinks = ({
  title,
  items,
  icon,
}: FooterLinksProps) => {
  return (
    <div className={'d-flex flex-column gap-2 justify-content-start'}>
      <div className={'d-flex gap-1'}>
        {icon}
        <div style={{ color: 'var(--primary-color)' }} className={'fw-bold'}>
          {title}
        </div>
      </div>

      {items.map((it, index) => (
        <div className={'d-flex gap-1'} key={index}>
          <span style={{ width: '20px' }}></span>
          {it.link ? (
            <Link
              color={'#ffffff'}
              style={{ textDecoration: 'underline', fontSize: '14px' }}
              href={it.link}
              target={'_blank'}
            >
              {it.label}
            </Link>
          ) : (
            <>
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  //window.Bspot.liveChat.open();
                }}
              >
                {' '}
                {it.label}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export const FooterPaymentsDesktop = () => {
  return (
    <div
      className={
        'd-flex flex-column gap-2 justify-content-start  align-items-start '
      }
    >
      <div style={{ color: 'var(--primary-color)' }} className={'fw-bold'}>
        Accepted payments
      </div>
      <div
        className={'d-md-flex flex-wrap align-items-center'}
        style={{ gap: '32px' }}
      >
        <img
          alt='visa payment type'
          src={'/assets/images/payments/visa.png'}
          width={76}
          height={24}
          loading='lazy'
          style={{ opacity: '.6', width: '38px', height: '12px' }}
        />
        <img
          alt='mastercard payment type'
          src={'/assets/images/payments/mastercard.png'}
          width={54}
          height={40}
          loading='lazy'
          style={{ opacity: '.6', width: '27px', height: '20px' }}
        />
        <img
          alt='citi payment type'
          src={'/assets/images/payments/citi.png'}
          width={70}
          height={40}
          loading='lazy'
          style={{ opacity: '.6', width: '35px', height: '20px' }}
        />
        <img
          alt='bank of america payment type'
          src={'/assets/images/payments/bankofamerica.png'}
          width={90}
          height={48}
          loading='lazy'
          style={{ opacity: '.6', width: '45px', height: '24px' }}
        />
        <img
          alt='wells fargo payment type'
          src={'/assets/images/payments/wellsfargo.png'}
          width={48}
          height={48}
          loading='lazy'
          style={{ opacity: '.6', width: '24px', height: '24px' }}
        />
        <img
          alt='chase payment type'
          src={'/assets/images/payments/chase.png'}
          width={130}
          height={24}
          loading='lazy'
          style={{ opacity: '.6', width: '65px', height: '12px' }}
        />
      </div>
    </div>
  );
};

export const FooterPaymentsMobile = () => {
  return (
    <div className='container'>
      <div
        className={
          'd-flex flex-column gap-2 pt-2  justify-content-center align-items-center'
        }
      >
        <div style={{ color: 'var(--primary-color)' }} className={'fw-bold'}>
          Accepted payments
        </div>
        <div
          className='d-flex flex-wrap align-items-center justify-content-center  pb-4 mt-2'
          style={{ gap: '32px' }}
        >
          <img
            alt='visa payment type'
            src={'/assets/images/payments/visa.png'}
            width={76}
            height={24}
            loading='lazy'
            style={{ opacity: '.6', width: '38px', height: '12px' }}
          />
          <img
            alt='mastercard payment type'
            src={'/assets/images/payments/mastercard.png'}
            width={54}
            height={40}
            loading='lazy'
            style={{ opacity: '.6', width: '27px', height: '20px' }}
          />
          <img
            alt='citi payment type'
            src={'/assets/images/payments/citi.png'}
            width={70}
            height={40}
            loading='lazy'
            style={{ opacity: '.6', width: '35px', height: '20px' }}
          />
          <img
            alt='bank of america payment type'
            src={'/assets/images/payments/bankofamerica.png'}
            width={90}
            height={48}
            loading='lazy'
            style={{ opacity: '.6', width: '45px', height: '24px' }}
          />
          <img
            alt='wells fargo payment type'
            src={'/assets/images/payments/wellsfargo.png'}
            width={48}
            height={48}
            loading='lazy'
            style={{ opacity: '.6', width: '24px', height: '24px' }}
          />
          <img
            alt='chase payment type'
            src={'/assets/images/payments/chase.png'}
            width={130}
            height={24}
            loading='lazy'
            style={{ opacity: '.6', width: '65px', height: '12px' }}
          />
        </div>
      </div>
    </div>
  );
};

export const FooterLinksForDesktop = () => {
  return (
    <div className={'d-none d-md-flex flex-column '}>
      <div className={'container'}>
        <hr
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: 'var(--bg-darkish-color)',
            border: 'none',
          }}
        />
        <div className={'pt-4 d-flex pb-4 flex-row'}>
          <div className={'col-md-2 '}>
            <DesktopFooterLinks
              title={ExploreLinks.title}
              items={ExploreLinks.items}
              icon={<Compass />}
            />
          </div>
          <div className={'col-md-2 '}>
            <DesktopFooterLinks
              title={HelpLinks.title}
              items={HelpLinks.items}
              icon={<Help />}
            />
          </div>
          <div className={'col-md-2'}>
            <DesktopFooterLinks
              title={LegalLinks.title}
              items={LegalLinks.items}
              icon={<Legal />}
            />
          </div>
          <div className={'col-md-6 d-none d-md-block'}>
            <FooterPaymentsDesktop />
          </div>
        </div>
        <hr
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: 'var(--bg-darkish-color)',
            border: 'none',
          }}
        />
      </div>
    </div>
  );
};

export const FooterLinksForMobile = () => {
  return (
    <div className={'d-flex flex-column d-md-none'}>
      <div className={'col-12 mx-auto pb-4'}>
        <FooterPaymentsMobile />
      </div>
      <div
        className={' d-flex flex-column '}
        style={{
          borderBottom: '1px solid var(--bg-darkish-color)',
          borderTop: '1px solid var(--bg-darkish-color)',
        }}
      >
        <div
          className={'col-12 px-4 py-2'}
          style={{ borderBottom: '1px solid var(--bg-darkish-color)' }}
        >
          <MobileFooterLinks
            title={ExploreLinks.title}
            items={ExploreLinks.items}
            icon={<Compass />}
          />
        </div>
        <div
          className={'col-12 px-4  py-2'}
          style={{ borderBottom: '1px solid var(--bg-darkish-color)' }}
        >
          <MobileFooterLinks
            title={HelpLinks.title}
            items={HelpLinks.items}
            icon={<Help />}
          />
        </div>
        <div className={'col-12 px-4  py-2'}>
          <MobileFooterLinks
            title={LegalLinks.title}
            items={LegalLinks.items}
            icon={<Legal />}
          />
        </div>
      </div>
    </div>
  );
};

export const FooterMk2 = () => (
  <FooterBar data-cy='footer-bar'>
    <div className='container mt-4'>
      <div className='d-flex flex-md-row flex-column justify-content-md-between justify-content-center gap-4 pb-4'>
        <div
          className='d-flex gap-4 justify-content-center align-items-center'
          data-cy='badge-nav'
        >
          {badges.map((badge, i) => (
            <a key={i} href={badge.href ? badge.href : null}>
              <img
                alt='badge'
                loading='lazy'
                src={badge.path}
                height={32}
                width={80}
                style={{
                  height: '32px',
                  width: 'auto',
                }}
              />
            </a>
          ))}
        </div>
        <div className='d-flex gap-4 justify-content-center align-items-center'>
          <FbSocial />
          <XSocial />
          <IgSocial />
        </div>
      </div>
    </div>
    <FooterLinksForDesktop />
    <FooterLinksForMobile />

    <div className='container'>
      <div
        className='d-flex flex-md-row flex-column justify-content-md-between justify-content-center gap-2 pt-4'
        data-cy='bottom-nav'
        style={{ fontSize: '12px' }}
      >
        <div className={'col-12 col-md-6 justify-content-start'}>
          ©{new Date().getFullYear()} Game Play Network, Inc.{' '}
          <Link
            text={'Patent Protected'}
            size={TextSize.Hint}
            color={'var(--primary-color)'}
            href={'https://support.bspot.com/hc/en-us/articles/360047514831'}
          />
          . All Rights Reserved.
          <br /> Have a gambling problem & want help? Call 1-800-Gambler
        </div>
        <div className={'col-12 col-md-6'}>
          <div
            className={'d-flex gap-1 justify-content-center align-items-center'}
          >
            <img
              alt='under 21'
              src='/assets/images/21-badge.svg'
              loading='lazy'
              data-cy='under-age-img'
            />
            <div style={{ color: 'var(--text-grey-color)' }} />
            Persons under 21 are not allowed to open or have access to{' '}
            {CONFIG.BRAND}
            accounts.
          </div>
        </div>
      </div>
    </div>
  </FooterBar>
);
